import './style.css'
import b4w from "blend4web"
import {detect_mobile} from "./mobile_detection"
import {append_screens} from "./Screen_class"
import {append_doors} from "./Door_class"
import { append_elevators } from './Elevator_class'
import {config} from "./config"
//DEVELOPPER
import {objtochange} from "./gui" //just for debug panel

//var qual;
const ROT_SPEED = config.rotation_speed;
let character = null;
let touches=[];
const quality_choice = window.location.toString().split('?')[1];
b4w.register("custom_anchors_main", function(exports, require) {

const m_app   = b4w.require("app");
const m_data  = b4w.require("data");
const m_scenes = b4w.require("scenes");//Obj access
const m_main = b4w.require("main");

//FPS view
const m_cam = b4w.require("camera");
const m_cons = b4w.require("constraints");
const m_cont = b4w.require("container"); 
const m_mouse = b4w.require("mouse");
const m_ctl = b4w.require("controls");
const m_phy = b4w.require("physics");
const m_config = b4w.require("config");

exports.init = function() {
	
    m_app.init({
        canvas_container_id: config.CANVAS_CONTAINER_ID,
        callback: init_cb,
        physics_enabled: true,
        show_fps: config.debug_mode || config.SHOW_FPS,
        alpha: config.BACKGROUND_ALPHA,
        autoresize: config.AUTORESIZE,
		quality: detect_mobile()?config.QUALITY_MOBILE:config.QUALITY_PC,
		antialiasing: detect_mobile()?config.ANTIALISING_MOBILE:config.ANTIALISING_PC,
		allow_hidpi: detect_mobile()?config.ALLOW_HIDPI_MOBILE:config.ALLOW_HIDPI_PC,
		anisotropic_filtering: detect_mobile()?config.ANISOTROPIC_FILTERING_MOBILE:config.ANISOTROPIC_FILTERING_PC,
		reflections: detect_mobile()?config.REFLECTIONS_MOBILE:config.REFLECTIONS_PC,
		reflection_quality: detect_mobile()?config.REFLECTION_QUALITY_MOBILE:config.REFLECTION_QUALITY_PC,
		refractions: detect_mobile()?config.REFRACTIONS_MOBILE:config.REFRACTIONS_PC,
		shadows: detect_mobile()?config.SHADOWS_MOBILE:config.SHADOWS_PC,
		ssao: detect_mobile()?config.SSAO_MOBILE:config.SSAO_PC,
		canvas_resolution_factor: detect_mobile()?1:config.CANVAS_RESOLUTION_FACTOR,
		allow_cors: true,
		assets_min50_available:true,
		use_min50:detect_mobile()?config.USE_MIN50_ON_MOBILE:config.USE_MIN50_ON_PC,
        console_verbose: config.debug_mode,
		//max_fps:30,
		srgb_type: detect_mobile()?config.SRGB_TYPE_MOBILE:config.SRGB_TYPE_PC
    });
	//m_config.set("reflections", false);
}

function init_cb(canvas_elem, success) {
    if (!success) {
        console.log("b4w init failure");
        return;
    }
	load();
	
}

function load(){
	m_config.set("physics_uranium_path", "uranium/");
	m_config.set("prevent_caching", false); // should be TRUE during development and FALSE for the published product
	const preloader_cont = document.getElementById("preloader_cont");
    preloader_cont.style.visibility = "visible";
	//m_preloader.create_preloader({});
	let filename_q;
	switch(quality_choice){
		case '0':filename_q = "visite_virtuelle_LOW";
		if(config.debug_mode){console.log("low")};
		break;
		case '1':filename_q = "visite_virtuelle_MEDIUM";
		if(config.debug_mode){console.log("medium")};
		break;
		case '2':filename_q = "visite_virtuelle_ULTRA";
		if(config.debug_mode){console.log("ultra")};
		break;
		default: filename_q = "visite_virtuelle_MEDIUM";
		if(config.debug_mode){console.log("default, Medium")};
		break;
	}
	m_data.load("assets/"+ filename_q +".json", load_cb, preloader_cb);
	
}
/* *****************************FOR CTL MOBILE******** *************************  */

function setup_control_events(right_arrow, up_arrow,
                              left_arrow, down_arrow,
							  cam_up, cam_down,
							 cam_left, cam_right) {

    let touch_start_pos = new Float32Array(2);
    let move_touch_idx_R,move_touch_idx_L;
    const tap_elem_L = document.getElementById("control_tap_L");
    const control_elem_L = document.getElementById("control_circle_L");
    const tap_elem_L_offset = tap_elem_L.clientWidth / 2;
    const ctrl_elem_L_offset = control_elem_L.clientWidth / 2;
	const tap_elem_R = document.getElementById("control_tap_R");
    const control_elem_R = document.getElementById("control_circle_R");
    const tap_elem_R_offset = tap_elem_R.clientWidth / 2;
    const ctrl_elem_R_offset = control_elem_R.clientWidth / 2;

    function touch_start_cb(event) {
        event.preventDefault();

        const w = window.innerWidth;

        const touches = event.changedTouches;
		
        for (var i = 0; i < touches.length; i++) {
            const touch = touches[i];
            const x = touch.clientX;
            const y = touch.clientY;
			
            if (x < w / 2){// left side of the screen
			
			console.log("Left_side touch");
			
            touch_start_pos[0] = x;
            touch_start_pos[1] = y;
            move_touch_idx_L = touch.identifier;

            tap_elem_L.classList.add("show");
            tap_elem_L.style.left = x - tap_elem_L_offset + "px";
            tap_elem_L.style.top  = y - tap_elem_L_offset + "px";

            control_elem_L.classList.add("show");
            control_elem_L.style.left = x - ctrl_elem_L_offset + "px";
            control_elem_L.style.top  = y - ctrl_elem_L_offset + "px";
			
			}else{// right side of the screen
			
				console.log("right side touch");
				
				touch_start_pos[0] = x;
				touch_start_pos[1] = y;
				move_touch_idx_R = touch.identifier;

				tap_elem_R.classList.add("show");
				tap_elem_R.style.left = x - tap_elem_R_offset + "px";
				tap_elem_R.style.top  = y - tap_elem_R_offset + "px";

				control_elem_R.classList.add("show");
				control_elem_R.style.left = x - ctrl_elem_R_offset + "px";
				control_elem_R.style.top  = y - ctrl_elem_R_offset + "px";
			}
        }
    }


    function touch_move_cb(event) {
        event.preventDefault();

        m_ctl.set_custom_sensor(up_arrow, 0);
        m_ctl.set_custom_sensor(down_arrow, 0);
        m_ctl.set_custom_sensor(left_arrow, 0);
        m_ctl.set_custom_sensor(right_arrow, 0);
		
		m_ctl.set_custom_sensor(cam_up, 0);
		m_ctl.set_custom_sensor(cam_down, 0);
		m_ctl.set_custom_sensor(cam_left, 0);
		m_ctl.set_custom_sensor(cam_right, 0);

        const w = window.innerWidth;
		
		const touches = event.changedTouches;

        for (var i=0; i < touches.length; i++) {
            const touch = touches[i];
            const x = touch.clientX;
            const y = touch.clientY;

            if (x < w / 2){ // left side of the screen
                

            tap_elem_L.style.left = x - tap_elem_L_offset + "px";
            tap_elem_L.style.top  = y - tap_elem_L_offset + "px";

            const d_x = x - touch_start_pos[0];
            const d_y = y - touch_start_pos[1];

            const r = Math.sqrt(d_x * d_x + d_y * d_y);

            if (r < 16) // don't move if control is too close to the center
                break;

            const cos = d_x / r;
            const sin = -d_y / r;

            if (cos > Math.cos(3 * Math.PI / 8))
                m_ctl.set_custom_sensor(right_arrow, 1);
            else if (cos < -Math.cos(3 * Math.PI / 8))
                m_ctl.set_custom_sensor(left_arrow, 1);

            if (sin > Math.sin(Math.PI / 8))
                m_ctl.set_custom_sensor(up_arrow, 1);
				else if (sin < -Math.sin(Math.PI / 8))
					m_ctl.set_custom_sensor(down_arrow, 1);
			
			
			}else{//right part of the screen
					//CAMERA ROTATION
					tap_elem_R.style.left = x - tap_elem_R_offset + "px";
					tap_elem_R.style.top  = y - tap_elem_R_offset + "px";

					const d_x = x - touch_start_pos[0];
					const d_y = y - touch_start_pos[1];

					const r = Math.sqrt(d_x * d_x + d_y * d_y);

					if (r < 16) // don't move if control is too close to the center
						break;

					const cos = d_x / r;
					const sin = -d_y / r;

					if (cos > Math.cos(3 * Math.PI / 8))
						m_ctl.set_custom_sensor(cam_right, 1);
					else if (cos < -Math.cos(3 * Math.PI / 8))
						m_ctl.set_custom_sensor(cam_left, 1);

					if (sin > Math.sin(Math.PI / 8))
						m_ctl.set_custom_sensor(cam_up, 1);
					else if (sin < -Math.sin(Math.PI / 8))
						m_ctl.set_custom_sensor(cam_down, 1);
				
					}
        }
    }

    function touch_end_cb(event) {
        event.preventDefault();
		
		
		const touches = event.changedTouches;
			//console.log(touches);
        for (var i=0; i < touches.length; i++) {
				
			
            if (touches[i].identifier == move_touch_idx_L) {
                m_ctl.set_custom_sensor(up_arrow, 0);
                m_ctl.set_custom_sensor(down_arrow, 0);
                m_ctl.set_custom_sensor(left_arrow, 0);
                m_ctl.set_custom_sensor(right_arrow, 0);
				
				
				
                tap_elem_L.classList.remove("show");
                control_elem_L.classList.remove("show");
				
				
                move_touch_idx_L = null;
			};
			if(touches[i].identifier == move_touch_idx_R){
					
				m_ctl.set_custom_sensor(cam_up, 0);
				m_ctl.set_custom_sensor(cam_down, 0);
				m_ctl.set_custom_sensor(cam_left, 0);
				m_ctl.set_custom_sensor(cam_right, 0);
				tap_elem_R.classList.remove("show");
                control_elem_R.classList.remove("show");
				move_touch_idx_R = null;
			}
				

            }
        
    }

    document.getElementById(config.CANVAS_CONTAINER_ID).addEventListener("touchstart", touch_start_cb, false);
    
    document.getElementById(config.CANVAS_CONTAINER_ID).addEventListener("touchmove", touch_move_cb, false);

    document.getElementById(config.CANVAS_CONTAINER_ID).addEventListener("touchend", touch_end_cb, false);
    document.getElementById("controls_L").addEventListener("touchend", touch_end_cb, false);
	document.getElementById("controls_R").addEventListener("touchend", touch_end_cb, false);
}


/********************************END OF CTL MOBILE********************************** */
function load_cb(root) {
	/****************************/
	character = m_scenes.get_first_character();
	
	const right_arrow = m_ctl.create_custom_sensor(0);
    const left_arrow  = m_ctl.create_custom_sensor(0);
    const up_arrow    = m_ctl.create_custom_sensor(0);
    const down_arrow  = m_ctl.create_custom_sensor(0);
	
	const cam_up = m_ctl.create_custom_sensor(0);
    const cam_down = m_ctl.create_custom_sensor(0);
    const cam_left = m_ctl.create_custom_sensor(0);
    const cam_right = m_ctl.create_custom_sensor(0);
	
	const elapsed_sensor = m_ctl.create_elapsed_sensor();

    if(detect_mobile()) {
						setup_control_events(right_arrow, up_arrow,
											 left_arrow, down_arrow,
											 cam_up, cam_down,
											 cam_left, cam_right);
						setup_rotation(cam_up, cam_down, cam_left, cam_right, elapsed_sensor);
						//m_request_fullscreen();
						}
		

    setup_movement(up_arrow, down_arrow, right_arrow, left_arrow);
	/********************************/
	//m_app.enable_camera_controls();//for assign automatic keys, I didnt need it except if I want wasd quick keyboard controls

	camera_physics(root);
}

function camera_physics(data_id){
	// make camera follow the character
    const camobj = m_scenes.get_active_camera();
    
    m_cons.append_stiff_trans(camobj, character, [0, 0, 0.95]); //1m80
	// enable rotation with mouse
    const canvas_elem = m_cont.get_canvas();
    canvas_elem.addEventListener("mouseup", function(e) {
        m_mouse.request_pointerlock(canvas_elem);
		//detect_mobile()?m_request_fullscreen():'';
    }, false);
	
	
	
	
	canvas_elem.addEventListener("mousedown", main_canvas_clicked_cb, false);
}

function main_canvas_clicked_cb(e) { 
									//allow to catch the clicked objects

									const x = m_mouse.get_coords_x(e);
									const y = m_mouse.get_coords_y(e);

									const obj = m_scenes.pick_object(x, y);
									
									if(obj!=null){
									const name = obj.name;
									if(config.debug_mode){console.log("click on "+name)};
									};
									};
//*************************************************************************
function setup_rotation(cam_up,cam_down,cam_left,cam_right, elapsed_sensor) {
	
    const rotate_array = [
        cam_up,
		cam_down,
		cam_left,
		cam_right,
        elapsed_sensor,
    ];
	
	const up_logic = function(s){return (s[0])};
	const down_logic = function(s){return (s[1])};
    const left_logic  = function(s){return (s[2])};
    const right_logic = function(s){return (s[3])};

    function rotate_cb(obj, id, pulse) {

        let elapsed;
			m_ctl.check_sensor_manifold(obj, id)?
			elapsed = m_ctl.get_sensor_value(obj, id, 4):
			elapsed = 0.01;
			
        if (pulse == 1) {
            switch(id) {
			case "CAM_UP":
				m_cam.rotate_camera(obj, 0, elapsed * ROT_SPEED);
                break;
            case "CAM_DOWN":
				m_cam.rotate_camera(obj, 0, -elapsed * ROT_SPEED);
                break;
            case "CAM_LEFT":
                m_phy.character_rotation_inc(character, elapsed * ROT_SPEED, 0);
				m_cam.rotate_camera(obj, elapsed * ROT_SPEED, 0);
                break;
            case "CAM_RIGHT":
                m_phy.character_rotation_inc(character, -elapsed * ROT_SPEED, 0);
				m_cam.rotate_camera(obj, -elapsed * ROT_SPEED, 0);
                break;
            }
        }
    }
	const cam = m_scenes.get_object_by_name("Camera")
	
	m_ctl.create_sensor_manifold(cam, "CAM_UP", m_ctl.CT_CONTINUOUS,
        rotate_array, up_logic, rotate_cb);
    m_ctl.create_sensor_manifold(cam, "CAM_DOWN", m_ctl.CT_CONTINUOUS,
        rotate_array, down_logic, rotate_cb);
    m_ctl.create_sensor_manifold(cam, "CAM_LEFT", m_ctl.CT_CONTINUOUS,
        rotate_array, left_logic, rotate_cb);
    m_ctl.create_sensor_manifold(cam, "CAM_RIGHT", m_ctl.CT_CONTINUOUS,
        rotate_array, right_logic, rotate_cb);
}

function setup_movement(up_arrow, down_arrow, right_arrow, left_arrow){
	
	
	//Key assign
	const key_q = m_ctl.create_keyboard_sensor(m_ctl.KEY_Q);
	const key_a = m_ctl.create_keyboard_sensor(m_ctl.KEY_A);
	const key_s = m_ctl.create_keyboard_sensor(m_ctl.KEY_S);
	const key_d = m_ctl.create_keyboard_sensor(m_ctl.KEY_D);
	const key_z = m_ctl.create_keyboard_sensor(m_ctl.KEY_Z);
	const key_w = m_ctl.create_keyboard_sensor(m_ctl.KEY_W);
	const key_space = m_ctl.create_keyboard_sensor(m_ctl.KEY_SPACE);
	const key_shift = m_ctl.create_keyboard_sensor(m_ctl.KEY_SHIFT);

	const move_state = {
		left_right: 0,
		forw_back: 0
	}

	const move_array = [
	key_z, key_w, up_arrow,
	key_s, down_arrow,
	key_q, key_a, left_arrow,
	key_d, right_arrow,
	key_shift
	];
	
	const forward_logic  = function(s){return (s[0] || s[1] || s[2])};
    const backward_logic = function(s){return (s[3] || s[4])};
	const left_logic = function(s){return (s[5] || s[6] || s[7])};
	const right_logic = function(s){return (s[8] || s[9])};
	
	
	//Move control
	const move_cb = function(obj, id, pulse) {
		
    if (pulse == 1 ) {
        switch (id) {
        case "FORWARD":
            move_state.forw_back = 1;//console.log("avance");
            break;
        case "BACKWARD":
            move_state.forw_back = -1;
            break;
        case "LEFT":
            move_state.left_right = 1;
            break;
        case "RIGHT":
            move_state.left_right = -1;
            break;
        case "RUNNING":
            m_phy.set_character_move_type(obj, m_phy.CM_RUN);
            break;
        }
		
		
    } else {
        switch (id) {
        case "FORWARD":
        case "BACKWARD":
            move_state.forw_back = 0;
            break;
        case "LEFT":
        case "RIGHT":
            move_state.left_right = 0;
            break;
        case "RUNNING":
            m_phy.set_character_move_type(obj, m_phy.CM_WALK);
            break;
        }
		
		
    }

    m_phy.set_character_move_dir(obj, move_state.forw_back,
                                      move_state.left_right);
};

//Logic of move_cb function calls



m_ctl.create_sensor_manifold(character, "FORWARD", m_ctl.CT_TRIGGER,
    move_array, forward_logic, move_cb);
m_ctl.create_sensor_manifold(character, "BACKWARD", m_ctl.CT_TRIGGER,
     move_array, backward_logic, move_cb);
m_ctl.create_sensor_manifold(character, "LEFT", m_ctl.CT_TRIGGER,
    move_array, left_logic, move_cb);
m_ctl.create_sensor_manifold(character, "RIGHT", m_ctl.CT_TRIGGER,
    move_array, right_logic, move_cb);

const running_logic = function(s) {
   return (s[0] || s[1] || s[2] || s[3]
			|| s[4] || s[5] || s[6] || s[7]
			|| s[8] || s[9]	) && s[10];
}
m_ctl.create_sensor_manifold(character, "RUNNING", m_ctl.CT_TRIGGER,
    move_array, running_logic, move_cb);
	
	
//Character Jump
const jump_cb = function(obj, id, pulse) {
    m_phy.character_jump(obj);
}
if(config.ALLOW_JUMP){
m_ctl.create_sensor_manifold(character, "JUMP", m_ctl.CT_SHOT,
[key_space], null, jump_cb);};
		 
};
/* *************************************************************************** */
function walk_mode(){
	m_phy.set_character_move_type(character, m_phy.CM_WALK);
}
/******************************************************************************* */
function graphics_config(){
	m_scenes.set_color_correction_params({ brightness: config.BRIGHTNESS,
                                       contrast: config.CONTRAST,
                                       exposure: config.EXPOSURE,
                                       saturation: config.SATURATION });
									   
	m_scenes.set_god_rays_params({ god_rays_max_ray_length: config.GOD_RAYS_LENGTH,
                               god_rays_intensity: config.GOD_RAYS_INTENSITY,
                               god_rays_steps: config.GOD_RAYS_STEPS_PER_PASS });
							   
	m_scenes.set_bloom_params({ average_lum: config.BLOOM_AVERAGE_LUMINANCE,
								key: config.BLOOM_INTENSITY, 
								edge_lum: config.BLOOM_EDGE_LUMINANCE, 
								blur: config.BLOOM_BLUR });
								
	m_scenes.set_fog_params({ fog_intensity: config.MIST_MINIMUM,
                          fog_depth: config.MIST_DEPTH,
                          fog_start: config.MIST_START,
                          fog_height: config.MIST_HEIGHT });
}
/******************************************************************************* */
// function init_mobile(){
// 	screens_collision_test_mobile();
// 	doors_collision_test_mobile();
// }
// function init_computer(){
// 	m_main.append_loop_cb(screens_collision_test);//used to test collision between character and screens
// 	m_main.append_loop_cb(doors_collision_test);//used to test collision between character and doors
															
// }

function preloader_cb(percentage) {
									//m_preloader.update_preloader(percentage);
									const preload_line = document.getElementById("preload_line");
									preload_line.style.width = percentage + "%";
									if (percentage == 100) {
															if(config.debug_mode){console.log("Loading main scene finished");};
															css_style_init();
															append_screens(); //used to register screens
															append_doors();
															append_elevators();
															graphics_config();
															config.START_IN_WALK_MODE?walk_mode():"";
															//register_screens_collider_box();
															//register_doors_collider_box();
															//detect_mobile()?init_mobile():init_computer()
														};
									}
function css_style_init(){
	const preloader_cont = document.getElementById("preloader_cont");
	preloader_cont.classList.add('finish');
	const buttons = document.getElementById(config.BUTTONS_ID);
	
	
	buttons.classList.add('start');
	
}


});

b4w.require("custom_anchors_main").init(); 
