/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
   Author: Jeanclaude Stephane
   Contact: jeanclaude.stephane@hotmail.fr
   :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

export const config = {
//Art loading options

//LINKS
art_link : "../art/floor/",
manhattan_outside_street: "https://wallstreetnfts.io",
floor_number:11,

//************************************************************************************//


//GENERAL CONFIG
CANVAS_CONTAINER_ID : "webgl",
ELEVATOR_INTERNAL_BUTTON_ID: "elevator_internal_panel",
ELEVATOR_INTERNAL_BUTTON_SHOW_ID: "show",
ELEVATOR_BUTTON_CLASS : ".elevator_buttons",
INTERACTION_INFO_ID : "interaction_info",
INTERACTION_INFO_SHOW_ID : "show",
INTERACTION_INFO_ONPRESS_ID : "onPress",
INTERACTION_TOUCH : 'e',
BUTTONS_ID : "buttons",
exit_menu_touch : 'Escape',
FULLSIZE_MENU_ID : "fullSizeMenu",
FULLSIZE_MENU_SHOW_ID : "show",
FULLSCREEN_BUTTON_ID : "fullscreen_mode",
IFRAME_APPEND_ID : "append_art",
imagesFormats : [".jpg"],
AUTORESIZE : true,

//Controls
rotation_speed : 0.5,

//MOBILE
//the time to calculate if the character is near and watching a screen or not....
//On computer this time is 16ms but on mobile it cant be the same...
//I though 1500 is ok but you can tweak it, if this number is too small it will slow down a lot.
collision_testing_delay : 1500, //(ms)


//GENERAL GRAPHICS
BACKGROUND_ALPHA : true,
BRIGHTNESS : 0,
CONTRAST : 0,
EXPOSURE : 1,
SATURATION : 1,
CANVAS_RESOLUTION_FACTOR : 2,
/*         these parameter wont do anything           */
BLOOM_AVERAGE_LUMINANCE : 0.4,
BLOOM_INTENSITY : 0.25,
BLOOM_BLUR : 4,
BLOOM_EDGE_LUMINANCE : 1.6,
GOD_RAYS_INTENSITY : 2,
GOD_RAYS_LENGTH : 25,
GOD_RAYS_STEPS_PER_PASS : 10,
MIST_MINIMUM : 0,
MIST_DEPTH : 75,
MIST_START : 20,
MIST_HEIGHT : 0,

//COMPUTER GRAPHICS
QUALITY_PC : "P_CUSTOM", // "P_LOW", "P_HIGH", "P_ULTRA", "P_CUSTOM"
USE_MIN50_ON_PC : false, //Use lower texture or not?
SRGB_TYPE_PC : "SRGB_PROPER", // proper is slower but better, "SRGB_SIMPLE", "SRGB_PROPER"
ANTIALISING_PC : true,
ALLOW_HIDPI_PC : true,
ANISOTROPIC_FILTERING_PC : true,
REFLECTION_QUALITY_PC : "LOW", //"LOW", "MEDIUM", "HIGH"
/*             these parameter wont do anything                */
REFLECTIONS_PC : true,//if you change this one it will crash.
REFRACTIONS_PC : false, //keep it false, there is no refractions in this scene
SHADOWS_PC : false, //keep it false, there is no shadows in this scene
SSAO_PC : false, //Keep it false

//MOBILE GRAPHICS
QUALITY_MOBILE : "P_CUSTOM", // "P_LOW", "P_HIGH", "P_ULTRA", "P_CUSTOM"
USE_MIN50_ON_MOBILE : true,
SRGB_TYPE_MOBILE : "SRGB_SIMPLE", //"SRGB_SIMPLE", "SRGB_PROPER"
ANTIALISING_MOBILE : false,
ALLOW_HIDPI_MOBILE : true,
ANISOTROPIC_FILTERING_MOBILE : false,
REFLECTION_QUALITY_MOBILE : "LOW", //"LOW", "MEDIUM", "HIGH"
/*             these parameter wont do anything                */
REFLECTIONS_MOBILE : true,
REFRACTIONS_MOBILE : false,
SHADOWS_MOBILE : false,
SSAO_MOBILE : false,



//PHYSICS
ALLOW_JUMP : true, //only on computer
START_IN_WALK_MODE : true, //if its false, you will fly
detection_distance : 5, //distance to detect when character is near a screen
angle_tolerance : 15,

//DEBUGGING FOR DEVELOPPER
debug_mode : false,
SHOW_FPS : false

   }