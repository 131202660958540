import {Object_interract} from "./Object_interact_class"
import {open_page} from "./interaction_html"
import { config } from "./config";
import {detect_mobile} from "./mobile_detection"
import b4w from "blend4web"

const m_main = b4w.require("main");
const door_prefix = "door";
var door;
"use strict"
export class Door extends Object_interract{
	constructor(temp_obj_name){
		super(temp_obj_name);
		this.src = config.manhattan_outside_street
		this.playerInterraction = () => {
			open_page(this.src)
		}
	}
}


export function append_doors(){
    door = new Door(door_prefix)  
	door.collide_register()
	detect_mobile()?
	doors_collision_test_mobile():
	m_main.append_loop_cb(doors_collision_test);//used to test collision between character and doors													
}

export function doors_collision_test(){
    door.collide_process()
}
export function doors_collision_test_mobile(){
	door.collide_process()
	setTimeout(doors_collision_test_mobile, config.collision_testing_delay)
}