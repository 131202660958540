/* ==========================================================================
   Author: Jeanclaude Stephane
   Contact: jeanclaude.stephane@hotmail.fr
   usage: activate this class by using append_objects(); at the end of loading the scene
   Register collider box by using register_collider_box() one time once scene is loaded
   Activate collision by using m_main.append_loop_cb(collision_test);
   after loading the scene
   ========================================================================== */
import b4w from "blend4web"
import {Object_interract} from "./Object_interact_class"
import {config} from "./config";
import {number} from "./scene_number"
import {detect_mobile} from "./mobile_detection"
import axios from 'axios'

   //DEVELOPPER CONFIG
const screen_central_number = 8;
const screen_wall_number = 12;
const collider_object = []

const screen_central_prefix = "Screen_central";
const screen_wall_prefix = "Screen_wall";


//utilities
const m_geom = b4w.geometry;
const m_tex = b4w.textures;
const m_mat = b4w.material;
const m_main = b4w.require("main");
const m_scenes = b4w.scenes;

//Screen variable to fit for your usage
export var screen_obj_central = [];
export var screen_obj_wall = [];

"use strict"
export class Screen extends Object_interract{
	constructor(temp_obj_name){
		super(temp_obj_name);
		this.mat_name = "Screen_central.000";
		this.video={
			switchName:'switchVideo',
			switchValue:0,
			videoTextureName:'Screen_video'
		}			
		this.img = 0;
		this.switched = true;
		this.set_visibility(false)
	}
	
	set_img_screen(img){
		this.img = img;
		if(config.debug_mode){console.log(this.obj_name + " -Updating screen images ");};
		m_tex.replace_image(this.obj.objectToInterract, this.mat_name, img, this.change_img_cb);
	}
	change_img_cb(){
		//_wait_for_image_loading = false;
	}
	
	loadImage(url) {
							  const image = new Image();
							  image.addEventListener("load",
								() => {
									//On success
									this.set_img_screen(image)
									// screen_obj_wall.push(this);
									// screen_obj_central.push(this)
									collider_object.push(this)
									this.collide_register()
									this.set_visibility(true)

								}
							  );

							  image.addEventListener("error", () => {
								//console.log("something go wrong");
								if(this.loadingError<config.imagesFormats.length-1){
								this.loadingError+=1;
								this.loadImage(this.src.split('img')[0]+'img'+config.imagesFormats[this.loadingError]);//tenter de charger les autres extension
								}
								
							});

							  image.crossOrigin = "anonymous";
							  image.alt = "";
							  image.src = url;
							  this.src = url;
							}
	
	
	set_ratioZ(ratio){
		if(config.debug_mode){console.log("set "+this.obj_name+" ratioZ to: "+ratio)};
		m_geom.set_shape_key_value(this.obj.objectToInterract, "ratioZ", ratio);
	}
	set_ratioY(ratio){
		if(config.debug_mode){console.log("set "+this.obj_name+" ratioY to: "+ratio)};
		m_geom.set_shape_key_value(this.obj.objectToInterract, "ratioY", ratio);
	}
	get_ratioZ(){
		var ratio = m_geom.get_shape_key_value(this.obj.objectToInterract, "ratioZ");
		if(config.debug_mode){console.log("read "+this.obj_name+" ratioZ : "+ratio)};
		return ratio
	}
	get_ratioY(){
		var ratio = m_geom.get_shape_key_value(this.obj.objectToInterract, "ratioY");
		if(config.debug_mode){console.log("read "+this.obj_name+" ratioY : "+ratio)};
		return ratio
	}
	set_tilt(tilt){
		if(config.debug_mode){console.log("set "+this.obj_name+" tilt to: "+tilt)};
		m_geom.set_shape_key_value(this.obj.objectToInterract, "inclinaison", tilt);
	}
	get_tilt(){
		var tilt = m_geom.get_shape_key_value(this.obj.objectToInterract, "inclinaison");
		if(config.debug_mode){console.log("read "+this.obj_name+" tilt : "+tilt)};
		return tilt
	}
	set_video_on(value){
		m_mat.set_nodemat_value(this.obj.objectToInterract, [this.mat_name, this.video.switchName], value);

	}
	set_switch_on(value){
		if(typeof value === "boolean"){
										this.switched = value;
										
										if(value){
												value = 1;
												}else{
												value = 0;
												};
										m_mat.set_nodemat_value(this.obj.objectToInterract, [this.mat_name,"on"], value);
		
		};
	}
	get_switch_on(){
		var switch_value = m_mat.get_nodemat_value(this.obj.objectToInterract, [this.mat_name,"on"]);
										if(switch_value == 1){
												switch_value = true;
												}else{
												switch_value = false;
												};
		if(config.debug_mode){
			if(this.switched){
			console.log("the screen "+this.obj_name+" is on")}else{
			console.log("the screen "+this.obj_name+" is off")
			};
			
			};
		return switch_value
	}
	set_glow_intensity(value){
		if(config.debug_mode){console.log("set "+this.obj_name+" glow_intensity to : "+value)};
		m_mat.set_nodemat_value(this.obj.objectToInterract, [this.mat_name,"glow_value"], value);
	}
	get_glow_intensity(){
		var glow_value = m_mat.get_nodemat_value(this.obj.objectToInterract, [this.mat_name,"glow_value"]);
		if(config.debug_mode){console.log("read "+this.obj_name+" glow_intensity to : "+glow_value)};
		return glow_value
	}
	set_visibility(value){
		if(value){
			m_scenes.show_object(this.obj.objectToInterract)
		}else{
			m_scenes.hide_object(this.obj.objectToInterract)
		}

	}
	
	
}


export function append_screens(){
	if(config.debug_mode){console.log("appending objects");};

	let im_number_central=0
	let im_number_walls=0
	for(var floor = 0; floor< config.floor_number; floor++){
		
		for(var i = 0; i < screen_central_number; i++) {
			if(config.debug_mode){console.log("Trying to load " + screen_central_prefix + "."+ number(im_number_central) + im_number_central);};
			
			const obj_name = screen_central_prefix + "."+ number(im_number_central) + im_number_central
			if(m_scenes.check_object_by_name(obj_name)){
			const screen_ob = new Screen(obj_name);
			screen_ob.set_video_on(0);
			axios.get(config.art_link +floor+"/central/"+ (i + 1) + "/config.json").then(response => {
				if (response.data.ratioY) {
					screen_ob.set_ratioY(response.data.ratioY)
				}
				if (response.data.ratioZ) {
					screen_ob.set_ratioZ(response.data.ratioZ)
				}
				if (response.data.screenTilt) {
					screen_ob.set_tilt(response.data.screenTilt)
				}
				screen_ob.loadImage(response.data.image);
				screen_ob.set_external_url(response.data.url)
			}).catch(response => console.error(response.message))
			}
			im_number_central++	
			 }
		
		 for(var i = 0; i < screen_wall_number; i++) {
			if(config.debug_mode){console.log("Trying to load " + screen_wall_prefix + "."+ number(im_number_walls) + im_number_walls);};
			
			const obj2_name = screen_wall_prefix + "."+ number(im_number_walls) + im_number_walls
			if(m_scenes.check_object_by_name(obj2_name)){
			const screen_ob = new Screen(obj2_name);
			screen_ob.set_video_on(0);
			axios.get(config.art_link +floor+"/walls/"+ (i + 1) + "/config.json").then(response => {
				if (response.data.ratioY) {
					screen_ob.set_ratioY(response.data.ratioY)
				}
				if (response.data.ratioZ) {
					screen_ob.set_ratioZ(response.data.ratioZ)
				}
				if (response.data.screenTilt) {
					screen_ob.set_tilt(response.data.screenTilt)
				}
				screen_ob.loadImage(response.data.image);
				screen_ob.set_external_url(response.data.url)
			}).catch(response => console.error(response.message))
			}
			im_number_walls++	
		}
		
	}
		detect_mobile()?
		screens_collision_test_mobile():
		m_main.append_loop_cb(screens_collision_test);//used to test collision between character and screens
	
};

export function screens_collision_test(){
	for(var i = 0; i < collider_object.length; i++) {
		collider_object[i].collide_process()
	}
}
export function screens_collision_test_mobile(){
	screens_collision_test()
	setTimeout(screens_collision_test_mobile, config.collision_testing_delay)
}
		