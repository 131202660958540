/* ==========================================================================
   Author: Jeanclaude Stephane
   Contact: jeanclaude.stephane@hotmail.fr
   ========================================================================== */

   import {config} from "./config"
   import * as html_interract from "./interaction_html"

export let keyboard_obj = {
					actionTouch:config.INTERACTION_TOUCH,
					action:null,
					tactileTouch:false
				   }

//keydown
//keyup
document.addEventListener('keyup', (event) => {
									const nomTouche = event.key;
									event.key==keyboard_obj.actionTouch?keyboard_obj.action='keyup':'';
									});
									
document.addEventListener('keydown', (event) => {
									const nomTouche = event.key;
									if(config.debug_mode){console.log(event.key)};
									event.key==keyboard_obj.actionTouch?keyboard_obj.action='keydown':'';
									event.key==config.exit_menu_touch?html_interract.hide_description_menu(true):'';
									});


