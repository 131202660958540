import b4w from "blend4web"
import {keyboard_obj} from "./keyboard"
import {config} from "./config";
import * as html_interract from "./interaction_html"

//utilities
const m_scenes = b4w.scenes;
const m_mat = b4w.material;
const m_trans = b4w.transform;


export class Object_interract{
	constructor(temp_obj_name){
		if(config.debug_mode){console.log("Loading: \"" + temp_obj_name + "\"");};
		
		this.obj_name = temp_obj_name;
		this.collision_distance_factor = 1;
		this.externalUrl = '#'
		this.obj = {
					objectToInterract: m_scenes.get_object_by_name(this.obj_name),
					collider: m_scenes.get_object_by_name(this.obj_name+"_collider"),
					characterCamera: m_scenes.get_object_by_name("Camera"),
					character: m_scenes.get_first_character()
					
					}
		this.playerInterraction = () => {
			if(config.debug_mode){console.log("config.INTERACTION_TOUCH "+config.INTERACTION_TOUCH +" released");};
												html_interract.hide_interract_menu();
												html_interract.show_description_menu(this.externalUrl);
												
		}
		this.interract_on_player_look_at = (value) => {
			if(config.debug_mode){console.log("I am watching objectToInterract "+this.obj.objectToInterract.name);};
			if(value){
					html_interract.show_interract_menu();
			}else{
					html_interract.hide_interract_menu();
					}
			
		}
		this.lookAtObject;
		this.loadingError=0;
	}
	set_external_url(value) {
		this.externalUrl = value
	}
	set_collision_distance_factor(value){
		this.collision_distance_factor = value;
	}
	get_obj(){
		return this.obj
	}
	collide_register(){
		m_mat.inherit_material(this.obj.collider,"collider_logged",this.obj.collider,"collider_unknow");
	}
	collide_process(){
		var value;
		if(this.characterCollide(this.obj.character)){
			value = 1;
			this.compare_angle();
		}else{
			value = 0;
			if(this.lookAtObject==true){this.set_lookAt(false)};
		}
		m_mat.set_nodemat_value(this.obj.collider, ["collider_unknow","collision"], value);
		
		//Interract with keyboard and menu
		if(this.get_lookAt()){
							if(keyboard_obj.action=='keydown'){
												if(config.debug_mode){console.log("config.INTERACTION_TOUCH "+config.INTERACTION_TOUCH +" pressed");};
												keyboard_obj.action=null;
												html_interract.press_on_interract_menu();
												};
							if(keyboard_obj.action=='keyup'){
								keyboard_obj.action=null;
								this.playerInterraction()
												};
							if(keyboard_obj.tactileTouch){
												html_interract.hide_interract_menu();
												html_interract.show_description_menu(this.src);
												keyboard_obj.tactileTouch = false;
												}
							}
	}
	
	
	// this.interract_on_player_look_at = (value) => {
	// 	if(config.debug_mode){console.log("I am watching objectToInterract "+this.obj.objectToInterract.name);};
	// 	if(value){
	// 			html_interract.show_interract_menu();
	// 	}else{
	// 			html_interract.hide_interract_menu();
	// 			}
		
	// }
	
	compare_angle(){	
					
					var cam = m_trans.get_translation(this.obj.characterCamera);// get location of Camera
					var mky = m_trans.get_translation(this.obj.objectToInterract);// get location of Object
					var a1 = m_trans.get_rotation_euler(this.obj.characterCamera)[2] * 180 / 3.1415;// get angle of Camera in degress
					var a2 = Math.atan2(mky[1] - cam[1], mky[0] - cam[0]) * 180 / 3.1415 - 90; // get angle...
					a2 = a2 < -180 ? a2 + 360 : a2;
  
					if(this.angle_diff(a1,a2) < config.angle_tolerance){
							this.lookAtObject==null || this.lookAtObject==false?this.set_lookAt(true):'';
							
							}else{
							this.lookAtObject==null || this.lookAtObject==true?this.set_lookAt(false):'';
							}
							
					}
	set_lookAt(value){
		this.lookAtObject = value;
		this.interract_on_player_look_at(value);
	}
	get_lookAt(){
		return this.lookAtObject
	}
	
	angle_diff(a1,a2) {                               // returns the difference between two angles
	  var d = Math.abs(a2 - a1) % 360;
	  return d > 180 ? 360 - d : d;
	}
	/* ************************************************* */
	get_collider_distance(character){
		return m_trans.distance(character, this.obj.collider);
	}
	get_collider_size(){
		return m_trans.get_object_size(this.obj.collider);
	}
	get_collider_scale(){
		return m_trans.get_scale(this.obj.collider);
	}
	characterCollide(character){
		return (config.detection_distance*this.collision_distance_factor) > this.get_collider_distance(character) * this.get_collider_scale()
	}
	
	
}