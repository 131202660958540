import * as dat from 'dat.gui'
import {screen_obj_central, screen_obj_wall} from "./Screen_class"
import b4w from "blend4web"
const m_phy = b4w.require("physics")
const m_scenes = b4w.require("scenes")
const m_cam = b4w.require("camera")
const m_config = b4w.require("config")

export let objtochange = {
    screen:{
        tilt:0,
        glowcentral:0,
        glowwall:0,
        switchcentral:true,
        switchwall:true,
        ratioWidth_central: 1,
        ratioHeight_central: 1,
        ratioWidth_wall: 1,
        ratioHeight_wall: 1
    },
    sun:{
        position:{
            x:0,
            y:0,
            z:0
        },
        color:0xff0000
    },
    character:{
        fly:false,
        run:false,
        fly_velocity:12,
        walk_velocity:6,
        run_velocity:12
    },
    mouse:{
        sensitivity:80
    },
    camera:{
        fov:0.5
    },
    config:{
        allow_hidpi:true,
        alpha: true,
        anisotropic_filtering:true,
        antialiasing:false,
        canvas_resolution_factor:2,
        dof: false,
        god_rays:true,
        motion_blur:false,
        reflection_quality:"LOW", //"LOW", "MEDIUM" or "HIGH".

        stereo: "none",// "ANAGLYPH", "SIDEBYSIDE", "HMD" or "NONE".
    }
}

//m_phy.set_character_move_type(character, m_phy.CM_WALK);

const debug_pannel = window.location.toString().split('?');
console.log(debug_pannel);
const found = debug_pannel.find(elem => elem == 'debug');

if(found == 'debug'){

    m_config.set("prevent_caching", true); // should be TRUE during development and FALSE for the published product

    /**
     * Stats DEbug
     */
     const Stats = require('stats.js')
     const stats = new Stats()
     stats.showPanel(0)
     document.body.appendChild(stats.dom)
     requestAnimationFrame(
         function loop(){
             stats.update();
             requestAnimationFrame(loop)
         });
//declarer l'objet
const gui = new dat.GUI({width: 400})
//folders
const camera_folder = gui.addFolder('camera')
const character_folder = gui.addFolder('character')
const mouse_folder = gui.addFolder('mouse')
const screen_folder = gui.addFolder('Screens')
const screen_folder_walls = screen_folder.addFolder('Walls')
const screen_folder_centrals = screen_folder.addFolder('Centrals')

gui.open()

camera_folder.close()
character_folder.close()
mouse_folder.close()
screen_folder.close()


camera_folder.add(objtochange.camera, 'fov')
.min(0)
.max(1)
.step(0.01)
.name('fov')
.onChange(()=> 
            m_cam.set_fov(m_scenes.get_active_camera(),objtochange.camera.fov)
            )

character_folder.add(objtochange.character, 'walk_velocity')
.min(1)
.max(30)
.step(1)
.name('walk velocity')
.onChange(()=> 
            m_phy.set_character_walk_velocity(m_scenes.get_first_character(),objtochange.character.walk_velocity)
            )

character_folder.add(objtochange.character, 'run_velocity')
.min(1)
.max(50)
.step(1)
.name('run velocity')
.onChange(()=> 
            m_phy.set_character_run_velocity(m_scenes.get_first_character(),objtochange.character.run_velocity)
            )

character_folder.add(objtochange.character, 'fly_velocity')
.min(1)
.max(30)
.step(1)
.name('fly velocity')
.onChange(()=> 
            m_phy.set_character_fly_velocity(m_scenes.get_first_character(),objtochange.character.fly_velocity)
            )

character_folder.add(objtochange.character, 'fly')
.name('fly_mode')
.onChange(()=> 
            actualise_fly_mode()
            )
function actualise_fly_mode(){
    var character = m_scenes.get_first_character();
    if(objtochange.character.fly){
        m_phy.set_character_move_type(character, m_phy.CM_FLY);
    }else{
        m_phy.set_character_move_type(character, m_phy.CM_WALK);
    }
}

character_folder.add(objtochange.character, 'run')
.name('always_run')
.onChange(()=> 
            actualise_run_mode()
            )
function actualise_run_mode(){
    var character = m_scenes.get_first_character();
    if(objtochange.character.fly){
        m_phy.set_character_move_type(character, m_phy.CM_WALK);
    }else{
        m_phy.set_character_move_type(character, m_phy.CM_RUN);
    }
}

screen_folder_centrals.add(objtochange.screen, 'ratioWidth_central')
.min(0)
.max(2)
.step(0.01)
.name('ratio width')
.onChange(()=> 
            ratio_width_central()
            )
screen_folder_centrals.add(objtochange.screen, 'ratioHeight_central')
.min(0)
.max(2)
.step(0.01)
.name('ratio height')
.onChange(()=> 
            ratio_height_central()
            )
screen_folder_walls.add(objtochange.screen, 'ratioWidth_wall')
.min(0)
.max(2)
.step(0.01)
.name('ratio width')
.onChange(()=> 
            ratio_width_wall()
            )
screen_folder_walls.add(objtochange.screen, 'ratioHeight_wall')
.min(0)
.max(2)
.step(0.01)
.name('ratio height')
.onChange(()=> 
            ratio_height_wall()
            )
function ratio_width_central(){
    for(var i = 0; i<screen_obj_central.length;i++){
        screen_obj_central[i].set_ratioY(objtochange.screen.ratioWidth_central);
    }
}
function ratio_height_central(){
    for(var i = 0; i<screen_obj_central.length;i++){
        screen_obj_central[i].set_ratioZ(objtochange.screen.ratioHeight_central);
    }
}
function ratio_width_wall(){
    for(var i = 0; i<screen_obj_wall.length;i++){
        screen_obj_wall[i].set_ratioY(objtochange.screen.ratioWidth_wall);
    }
}
function ratio_height_wall(){
    for(var i = 0; i<screen_obj_wall.length;i++){
        screen_obj_wall[i].set_ratioZ(objtochange.screen.ratioHeight_wall);
    }
}

screen_folder_centrals.add(objtochange.screen, 'switchcentral')
.name('switch on/off')
.onChange(()=> 
            switch_central()
            )
screen_folder_walls.add(objtochange.screen, 'switchwall')
.name('switch on/off')
.onChange(()=> 
            switch_wall()
            )
function switch_central(){
    if(!objtochange.screen.switchcentral){glow_zero_central()}
    for(var i = 0; i<screen_obj_central.length;i++){
        screen_obj_central[i].set_switch_on(objtochange.screen.switchcentral);
    }
}
function switch_wall(){
    if(!objtochange.screen.switchwall){glow_zero_wall()}
    for(var i = 0; i<screen_obj_wall.length;i++){
        screen_obj_wall[i].set_switch_on(objtochange.screen.switchwall);
    }
}

screen_folder_centrals.add(objtochange.screen, 'glowcentral')
.min(0)
.max(0.5)
.step(0.01)
.name('glow')
.onChange(()=> 
            glow_central()
            )
screen_folder_walls.add(objtochange.screen, 'glowwall')
.min(0)
.max(0.5)
.step(0.01)
.name('glow')
.onChange(()=> 
            glow_wall()
            )
function glow_zero_central(){
    for(var i = 0; i<screen_obj_central.length;i++){
        screen_obj_central[i].set_glow_intensity(0);
    }
    
}
function glow_zero_wall(){
    for(var i = 0; i<screen_obj_wall.length;i++){
        screen_obj_wall[i].set_glow_intensity(0);
    }
}

 function glow_central(){
        if(objtochange.screen.switchcentral){
        for(var i = 0; i<screen_obj_central.length;i++){
            screen_obj_central[i].set_glow_intensity(objtochange.screen.glowcentral);
        }
    }
 }   
 function glow_wall(){
            if(objtochange.screen.switchwall){
                for(var i = 0; i<screen_obj_wall.length;i++){
                    screen_obj_wall[i].set_glow_intensity(objtochange.screen.glowwall);
                }
        }
 }        
screen_folder_centrals.add(objtochange.screen, 'tilt')
.min(-0.9)
.max(0.2)
.step(0.01)
.onChange(()=> 
            tilt()
            )

function tilt(){
    for(var i = 0; i<screen_obj_central.length;i++){
        screen_obj_central[i].set_tilt(objtochange.screen.tilt);
    }

}


}