/* ==========================================================================
   Author: Jeanclaude Stephane
   Contact: jeanclaude.stephane@hotmail.fr
   ========================================================================== */
   import b4w from "blend4web"
   import {config} from "./config"
   import {keyboard_obj} from "./keyboard"
   import axios from 'axios'
   
   const m_screen = b4w.screen;
   const m_mouse = b4w.mouse;
   
   const interract_menu = document.getElementById(config.INTERACTION_INFO_ID);
   const interract_menu_appear_style_ID = config.INTERACTION_INFO_SHOW_ID;
   const interract_menu_onPressStyle_ID = config.INTERACTION_INFO_ONPRESS_ID;
   const description_menu = document.getElementById(config.FULLSIZE_MENU_ID);
   const descrition_menu_appear_style_ID = config.FULLSIZE_MENU_SHOW_ID;
   const fullscreen_button = document.getElementById(config.FULLSCREEN_BUTTON_ID);
   const iframe_append = document.getElementById(config.IFRAME_APPEND_ID);
   const elevator_internal_menu = document.getElementById(config.ELEVATOR_INTERNAL_BUTTON_ID)
   const elevator_internal_appear_menu_ID = config.ELEVATOR_INTERNAL_BUTTON_SHOW_ID
   
   const elevator_button = document.querySelector(config.ELEVATOR_BUTTON_CLASS)
   
   export function show_internal_elevator_menu(){
	   elevator_internal_menu.classList.add(elevator_internal_appear_menu_ID);
   }
   export function hide_internal_elevator_menu(){
	   elevator_internal_menu.classList.remove(elevator_internal_appear_menu_ID);
   }
   export function press_on_interract_menu(){//Press with interact touch
					   interract_menu.classList.add(interract_menu_onPressStyle_ID);
				   }
   export function show_interract_menu(){
					   interract_menu.classList.add(interract_menu_appear_style_ID);
				   }
   export function open_page(src){
				   window.open(src,"_self");
				   }
   export function append_content(src){
	   var linksource = src.slice(0,src.length - 7);
	   linksource += "config.json";
      axios.get(linksource).then(response => {
         window.open(response.data.url, '_blank')
      })
	   
   }
   export function hide_interract_menu(){
					   interract_menu.classList.remove(interract_menu_onPressStyle_ID);
					   interract_menu.classList.remove(interract_menu_appear_style_ID);
				   }
   export function show_description_menu(src){
					   window.open(src, '_blank');
				   }
   export function hide_description_menu(value){
					   var canvas = document.getElementById(config.CANVAS_CONTAINER_ID);
					   description_menu.classList.remove(descrition_menu_appear_style_ID);
					   /*if(detect_mobile()){
					   value!=null?'':m_mouse.request_pointerlock(canvas);
					   }*/
					   m_mouse.request_pointerlock(canvas);
					   //console.log(m_screen.check_fullscreen());
					   
				   }
   
   /*************for mobile touch ***********************/									
   function touch_on_interract_menu(){//touch with finger on mobile
	   //hide_interract_menu();
	   //show_description_menu();
	   keyboard_obj.tactileTouch = true;
   }
				   
   
   
   var exit_button = document.getElementById('quit');
   exit_button.addEventListener("click", (event) => {
	   hide_description_menu()
   });
   exit_button.addEventListener("touchstart", (event) => {
	   hide_description_menu()
   });
   exit_button.addEventListener("touchend", (event) => {
	   hide_description_menu()
   });
   
   interract_menu.addEventListener("touchstart", (event) => {
	   press_on_interract_menu()
   });
   
   interract_menu.addEventListener("touchend", (event) => {
	   touch_on_interract_menu()
   });
   fullscreen_button.addEventListener("click", (event) => {
	   console.log("asking for fullscreen");
	   //event.stopPropagation();
	   m_screen.request_fullscreen();
	   
   });
   fullscreen_button.addEventListener("touchstart",(event)=>{
	   m_screen.request_fullscreen();
   })
   fullscreen_button.addEventListener("touchend",(event)=>{
	   m_screen.request_fullscreen();
   })
   
   
   for(let i=0; i<elevator_internal_menu.children.length; i++){
   console.log(elevator_internal_menu.children[i])
   elevator_internal_menu.children[i].addEventListener("click", (event) => {
   
	   const e = require('./Elevator_class')
	   e.elevator.move_elevator(event.target.id)
	   
   })
   elevator_internal_menu.children[i].addEventListener("touchstart", (event) => {
   
	   const e = require('./Elevator_class')
	   e.elevator.move_elevator(event.target.id)
	   
   })
   elevator_internal_menu.children[i].addEventListener("touchend", (event) => {
   
	   const e = require('./Elevator_class')
	   e.elevator.move_elevator(event.target.id)
	   
   })
   }
   
   