import {Object_interract} from "./Object_interact_class"
import { config } from "./config"
import {detect_mobile} from "./mobile_detection"
import * as html_interract from "./interaction_html"
import b4w from "blend4web"
import gsap from "gsap"

const m_scenes = b4w.require("scenes")//Obj access
const m_main = b4w.require("main")
const m_geom = b4w.geometry
const m_mouse = b4w.mouse
const m_transform = b4w.transform
const m_phys = b4w.physics

// const ELEVATOR_PREFIX = "Outside_door";
// const PANEL_PREFIX = "boitier_interne_head";
// const PANEL_MIRROR_PREFIX = "boitier_interne_head_mirror";
const collide_element = []
const external_button_list =
    [
        "boitier_externe_lvl_regular_01",
        "boitier_externe_lvl_mirror_01",
        "boitier_externe_lvl_regular_02",
        "boitier_externe_lvl_mirror_02",
        "boitier_externe_lvl_regular_03",
        "boitier_externe_lvl_mirror_03",
        "boitier_externe_lvl_regular_04",
        "boitier_externe_lvl_mirror_04",
        "boitier_externe_lvl_regular_05",
        "boitier_externe_lvl_mirror_05",
        "boitier_externe_lvl_regular_06",
        "boitier_externe_lvl_mirror_06",
        "boitier_externe_lvl_regular_07",
        "boitier_externe_lvl_mirror_07",
        "boitier_externe_lvl_regular_08",
        "boitier_externe_lvl_mirror_08",
        "boitier_externe_lvl_regular_09",
        "boitier_externe_lvl_mirror_09",
        "boitier_externe_lvl_regular_10",
        "boitier_externe_lvl_mirror_10",
        "boitier_externe_lvl_regular_blueHorse",
        "boitier_externe_lvl_mirror_blueHorse"
    ]
export let elevator;
const character_state={
    position:null
}
const elevator_state = {
    activation:false,
    movement:null,
    position:()=>{
        const elev_anchor = m_scenes.get_object_by_name('Anchor_elevator')
        const pos = m_transform.get_translation(elev_anchor)
        return pos
    }
}
const elevator_doors_state = {
    regular:{
        obj:()=>{
            return m_scenes.get_object_by_name("Inside_door")
        },
        opening:false,
        isOpen:false,
        shapekey_value:0,
        collider_position:{
			R:null,
			L:null
		},
        close_position:{
            right:()=>{
            const ob = m_scenes.get_object_by_name("elev_closed_R")
            const pos = m_transform.get_translation(ob)
            return pos
            },
            left:()=>{
            const ob = m_scenes.get_object_by_name("elev_closed_L")
            const pos = m_transform.get_translation(ob)
            return pos
            }
        },
        open_position:{
            right:()=>{
            const ob = m_scenes.get_object_by_name("elev_open_R")
            const pos = m_transform.get_translation(ob)
            return pos
            },
            left:()=>{
            const ob = m_scenes.get_object_by_name("elev_open_L")
            const pos = m_transform.get_translation(ob)
            return pos
            }
        }
    },
    mirror:{
        obj:()=>{
           return m_scenes.get_object_by_name("Inside_door_mirror")
        },
        close_position:{
            right:()=>{
            const ob = m_scenes.get_object_by_name("elev_closed_R_mirror")
            const pos = m_transform.get_translation(ob)
            return pos
            },
            left:()=>{
            const ob = m_scenes.get_object_by_name("elev_closed_L_mirror")
            const pos = m_transform.get_translation(ob)
            return pos
            }
        },
        open_position:{
            right:()=>{
            const ob = m_scenes.get_object_by_name("elev_open_R_mirror")
            const pos = m_transform.get_translation(ob)
            return pos
            },
            left:()=>{
            const ob = m_scenes.get_object_by_name("elev_open_L_mirror")
            const pos = m_transform.get_translation(ob)
            return pos
            }
        }
    }
}

"use strict"
export class Elevator_internal_button extends Object_interract{
	constructor(temp_obj_name){
		super(temp_obj_name);
		this.toggle_open = false;

        this.interract_on_player_look_at = (value) => {
            
                if(value){
					html_interract.show_internal_elevator_menu();
                    // console.log("ouvrir le menu interne")
                    // setTimeout(elevator.close_elevator_doors, 1500)
                    // setTimeout(elevator.close_elevator_doors_mirror, 1500)
                    }else{
                        html_interract.hide_internal_elevator_menu();
                        // console.log("fermer le menu interne") 
                        }
            
        }

		this.playerInterraction = () => {
            //Au press de E ??
            m_mouse.exit_pointerlock();
        }
	}
}

export class Elevator_external_button extends Object_interract{
	constructor(temp_obj_name){

		super(temp_obj_name);
		this.toggle_open = false;
        this.toggle_open_mirror = false;
        this.isRegular = ()=>{
            let a;
            temp_obj_name.indexOf('regular')!=-1?
            a= true:a= false
            return a
        }

        this.interract_on_player_look_at = (value) => {
            
            if(detect_mobile()){
                // console.log("open the door")
            }else{
                if(value){
					html_interract.show_interract_menu();
                    }else{
                            html_interract.hide_interract_menu();
                            // setTimeout(this.close_elevator_doors, 8000)
                            // setTimeout(this.close_elevator_doors_mirror, 8000)
                            this.toggle_open = false
                            this.toggle_open_mirror = false
                            }
            }
        }

		this.playerInterraction = () => {
            html_interract.hide_interract_menu();
            this.toggle_elevator_doors()


        }
	}

    toggle_elevator_doors(){
        if(this.isRegular()){
            //open regular elevator door
            elevator.toggle_open_doors()
        }else{
            //open mirror elevator door
            elevator.toggle_open_doors()
        }
    }
}

class Elevator {
    constructor(){
        this.elevatorObject = m_scenes.get_object_by_name("Anchor_elevator")
        this.internal_button_regular = new Elevator_internal_button("boitier_interne_head")
        this.internal_button_mirror = new Elevator_internal_button("boitier_interne_head_mirror")
        this.internal_button_regular.set_collision_distance_factor(0.5)
        this.internal_button_mirror.set_collision_distance_factor(0.5)
        this.internal_button_regular.collide_register()
        this.internal_button_mirror.collide_register()
        collide_element.push(this.internal_button_regular)
        collide_element.push(this.internal_button_mirror)
        m_main.append_loop_cb(this.draw);
    }
    compare_angle(){	
        const characterCam = m_scenes.get_object_by_name("Camera")
        const mirror_side = m_scenes.get_object_by_name('angle_cam_mirror')
    
        var cam = m_transform.get_translation(characterCam);// get location of Camera
        var mky = m_transform.get_translation(mirror_side);// get location of Object
        var a1 = m_transform.get_rotation_euler(characterCam)[2] * 180 / 3.1415;// get angle of Camera in degress
        var a2 = Math.atan2(mky[1] - cam[1], mky[0] - cam[0]) * 180 / 3.1415 - 90; // get angle...
        a2 = a2 < -180 ? a2 + 360 : a2;
        let lookAt=null;
        if(angle_diff(a1,a2) < 80){
                lookAt=true
                }else{
                lookAt=false
                }
        return lookAt
        }
    
    angle_diff(a1,a2) {                               // returns the difference between two angles
            var d = Math.abs(a2 - a1) % 360;
            return d > 180 ? 360 - d : d;
          }
    move_elevator(value){
        // console.log(value)

    if(value=="elev_open"){
        // console.log("open the door")
        // open_right_door()
        elevator_doors_state.regular.opening = true
        this.open_doors()
    }else{
        //catch position
        const ob = m_scenes.get_object_by_name(value)
        const pos = m_transform.get_translation(ob)
        //catch time
        //apply time to gsap
        elevator_state.activation = true
        if(elevator_state.movement==null){
            elevator_state.movement=elevator_state.position()[2]
        }
        enlarge_wall(true)

        elevator_doors_state.regular.opening = true
        this.close_doors()
        setTimeout(
            ()=>{
                //block physic
                const character = m_scenes.get_first_character()
                m_phys.disable_simulation(character);
                character_state.position = m_transform.get_translation(character)
                //m_trans.set_translation(character, x, y, z);

                gsap.to(elevator_state,{
                    duration:1,
                    movement:pos[2],
                    ease: "none",
                    // CustomEase.create("custom", "M0,0,C0.398,0,0.592,1,1,1"),
                    onComplete:()=>{
                        setTimeout(
                            ()=>{
                                enlarge_wall(false)
                        elevator_doors_state.regular.opening = true
                        this.open_doors()
                        elevator_state.activation = false
                        //fixe the elevator level problem
                        // const elev_anchor = m_scenes.get_object_by_name('Anchor_elevator')
    
                        // m_transform.set_translation(
                        //     elev_anchor,
                        //     elevator_state.position()[0],
                        //     elevator_state.position()[1],
                        //     pos[2]
                        //     )
                        //restore physic
                        const character = m_scenes.get_first_character()
                        m_phys.enable_simulation(character);
                            },250
                            ) 
                        
                    }
                })
            },1000
        )
        
    }
    }
    
    toggle_open_doors(){

        if(!elevator_doors_state.regular.opening){
            elevator_doors_state.regular.opening = true
            if(!elevator_doors_state.regular.isOpen){
                this.open_doors()
            }else{
                this.close_doors()
            }
        }
    }

    open_doors(){
        this.open_regular_door()
    }
    open_regular_door(){
        //object 3d
        gsap.to(elevator_doors_state.regular,{
            duration:1,
            shapekey_value:1,
            onComplete:()=>{
                elevator_doors_state.regular.isOpen= true;
                elevator_doors_state.regular.opening = false;
                
                this.close_doors(8000)
            }
        })
        //collider
        gsap.to(elevator_doors_state.regular.collider_position,{
            duration:1,
            R:elevator_doors_state.regular.open_position.right()[1],
            L:elevator_doors_state.regular.open_position.left()[1]
        })
    }
    close_doors(value){
        if(value==undefined){
        this.close_regular_door()
        }else{
            setTimeout(
                ()=>{
                    elevator_doors_state.regular.opening = true
                    this.close_regular_door()
                },value
            )
        }
    }
    close_regular_door(){
        //object 3d
        gsap.to(elevator_doors_state.regular,{
            duration:1,
            shapekey_value:0,
            onComplete:()=>{
                elevator_doors_state.regular.isOpen = false;
                elevator_doors_state.regular.opening = false;
                }
        })
        //collider
        gsap.to(elevator_doors_state.regular.collider_position,{
            duration:1,
            R:elevator_doors_state.regular.close_position.right()[1],
            L:elevator_doors_state.regular.close_position.left()[1]
        })
    }

    draw(){
        if(elevator_state.activation)
    {
    const elev_anchor = m_scenes.get_object_by_name('Anchor_elevator')
    
    m_transform.set_translation(
        elev_anchor,
        elevator_state.position()[0],
        elevator_state.position()[1],
        elevator_state.movement
        )
    if(character_state.position != undefined){
        const character = m_scenes.get_first_character()
        m_transform.set_translation(character, 
            character_state.position[0], 
            character_state.position[1], 
            elevator_state.movement -2.33
            );
        }
    }
        if(elevator_doors_state.regular.opening){
            // console.log("opening")
        m_geom.set_shape_key_value(elevator_doors_state.regular.obj(), "open", elevator_doors_state.regular.shapekey_value);
        m_geom.set_shape_key_value(elevator_doors_state.mirror.obj(), "open", elevator_doors_state.regular.shapekey_value);
        
        //capture les deux battants
        const battantL = m_scenes.get_object_by_name("door_collider_L")
        const battantR = m_scenes.get_object_by_name("door_collider_R")
        const battantL_m = m_scenes.get_object_by_name("door_collider_mirror_L")
        const battantR_m = m_scenes.get_object_by_name("door_collider_mirror_R")
        m_transform.set_translation(battantL,
            elevator_doors_state.regular.close_position.left()[0],
            elevator_doors_state.regular.collider_position.L,
            elevator_doors_state.regular.close_position.left()[2]
            )
        m_transform.set_translation(battantR,
            elevator_doors_state.regular.close_position.right()[0],
            elevator_doors_state.regular.collider_position.R,
            elevator_doors_state.regular.close_position.right()[2]
            )
        
        m_transform.set_translation(battantL_m,
            elevator_doors_state.mirror.close_position.left()[0],
            elevator_doors_state.regular.collider_position.L,
            elevator_doors_state.mirror.close_position.left()[2]
            )
        m_transform.set_translation(battantR_m,
            elevator_doors_state.mirror.close_position.right()[0],
            elevator_doors_state.regular.collider_position.R,
            elevator_doors_state.mirror.close_position.right()[2]
            )
            }
        }
    
}
function enlarge_wall(value){
    const ob_regular = m_scenes.get_object_by_name("wall_anchor_regular")
const pos_regular = m_transform.get_translation(ob_regular)

   
if(value){
    // console.log("enlarge wall")
    m_transform.set_translation(ob_regular,
        50,
        pos_regular[1],pos_regular[2])
}else{
    // console.log("back to normal the walls")
    const ob_regular_initial = m_scenes.get_object_by_name("wall_regular_initial")
    const pos_regular_initial = m_transform.get_translation(ob_regular_initial)
    m_transform.set_translation(ob_regular,
        pos_regular_initial[0],
        pos_regular[1],pos_regular[2])

}
}
export function append_elevators(){
    elevator = new Elevator()
    //append external buttons
    for(let i = 0; i<external_button_list.length;i++)
    {
        const external = new Elevator_external_button(external_button_list[i])
        external.collide_register()
        collide_element.push(external) 
    }
       
}

export function elevators_collision_test(){
    if(collide_element.length>0){
        for(let i =0; i<collide_element.length; i++){
            collide_element[i].collide_process()
        }
    }
}
export function elevators_collision_test_mobile(){
	elevators_collision_test()
	setTimeout(elevators_collision_test_mobile, config.collision_testing_delay)
}

detect_mobile()?
	elevators_collision_test_mobile():
	m_main.append_loop_cb(elevators_collision_test);